.default-tabs-horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media (max-width: 576px) {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }

    border: none;
    transition: all 0.2s ease-in-out;

    a {
      background-color: #dddddd;
      color: #333 !important;
      font-weight: 500 !important;
      padding: 0 !important;
      font-size: 14px;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      display: block;
      padding: 5px 1rem !important;
      font-size: 15px;

      @media (max-width: 991px) {
        padding: 5px 10px !important;
      }

      @media (max-width: 576px) {
        font-size: 12px;
      }
    }



    &.active {
      a {
        color: $white-color !important;
        background: $primary-color;
        color: #fff !important;
        position: relative;
        z-index: 1;
        border-bottom: 1px solid rgba(34, 154, 135, 0.3);
        box-shadow: 3px 2px 3px rgba(34, 154, 135, 0.4);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}