.ig-input {
  display: block;
  /* border: 1px dotted blue;
    padding: 10px; */
  font-size: 1rem;
  margin-bottom: 10px;
}
.ig-input-label {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 6px;
  width: 100%;
}
.ig-input-frame {
  display: block;
}
.ig-input .ig-form-input {
  width: 100%;
  border: 1.5px solid #cbcdcd;
  border-radius: 7px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 3px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.ig-input .ig-form-input.disabled {
  background-color: #f0f1f1;
}
.ig-input-select {
  cursor: pointer;
  position: relative;
}
.ig-input-select-options {
  position: absolute;
  background-color: #fff;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  width: 100%;
  border: 1.5px solid #cbcdcd;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #ddd;
  z-index: 100;
}
.ig-input-select-options .options-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flow-root;
  max-height: 40vh;
  overflow-y: scroll;
}
.ig-input-select-options .options-list li {
  padding: 0.55rem 0.7rem;
  font-size: 0.8rem;
}
.ig-input-select-options .options-list li:hover {
  color: #000;
}
.ig-input-select-options .options-list li.active {
  color: #229a87;
}
.ig-input .ig-form-input:hover {
  border-color: #000;
}
.ig-input .ig-form-input:disabled {
  border-color: #cbcdcd;
  background-color: #f9f9f9;
}
.ig-input .ig-form-input:focus {
  border-color: #229a87;
}
.ig-input .ig-form-input,
.ig-input .ig-form-input:focus {
  outline: none;
  box-shadow: none;
}
.ig-error-text {
  color: red;
  font-size: 0.7rem;
}
.ig-input .ig-form-input.ig-border-error {
  border-color: red;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.ig-overlay {
  position: absolute;
  right: 0;
  padding: 0.5rem;
  font-weight: bold;
}

@media (max-width: 400px) {
  .social-login-list-modal .col {
    padding: 5px !important;
  }
}

.eye-icon {
  background-image: url("../images/ic_look.png");
  background-repeat: no-repeat;
  width: 20px;
  background-position: right;
}

.ig-social-icon {
  background-color: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 2px;
  padding: 10px;
  box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
  cursor: pointer;
}
@media (max-width: 400px) {
  .ig-social-icon {
    padding: 5px;
  }
}
.ig-social-icon img {
  max-width: 30px;
}
.ig-phone select {
  margin-right: 5px;
  border: 1.5px solid #cbcdcd;
  border-radius: 7px;
  display: block;
  height: calc(1.5em + 0.75rem + 3px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 25%;
  color: #565b5c;
}
.ig-phone select option {
}
.ig-modal-background {
  background-image: url("../images/bg-modal.png");
}
.help-box {
  max-width: fit-content;
  position: relative;
}
.help-box:hover .help-box-content {
  display: block;
}
.help-box-content {
  position: absolute;
  right: 0;
  width: 200px;
  font-size: 0.7em;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 5px 10px;
  text-align: left;
  display: none;
}
