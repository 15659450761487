#profile-component {
  .heading-block {
    .heading {
      font-size: 1.2em;
      color: #229a87;
      font-weight: 600;
      margin-bottom: 0;
    }

    p {
      font-size: 0.9em;
      color: #6c757d !important;
      margin-bottom: 0;
      line-height: 20px;
    }

  }

  .edit-button {
    float: right;
    border: 1px solid #dc3545;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 15px;
    background: #dc3545;
    padding: 2px 10px;

    span {
      padding-left: 6px;
    }
  }

  .profile-data-view {
    margin-top: 20px;

    .sub-heading {
      font-size: 0.95em;
      color: #229a87;
      text-decoration: underline;
    }
  }
}