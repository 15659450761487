.page-banner {
  background: url("/images/slider_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  .slider-shape {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    opacity: 0.2;
    z-index: -1;
    background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
  }

  // background: #20c997;

  .inner {
    .page-name {
      color: #fff;
      margin-bottom: 0;
      font-size: 2em;
      padding-top: 50px;
      font-weight: 700;
      letter-spacing: 0.4px;
      // text-transform: uppercase;
    }

    img {
      width: 80px;
      display: table;
      margin: 0 auto;
      margin-top: 15px;
      padding-left: 15px;
    }
  }



}