#contact-page {
  .quick-info {
    padding: 4em 0;

    @media (max-width : 991px) {
      padding: 2em 0;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {


        &:not(:last-child) {
          margin-right: 40px;
        }

        @media (max-width: 991px) {
          margin-right: 0 !important;
          margin-bottom: 15px !important;
        }

        .each {
          background: #fff;
          box-shadow: 5px 0px 20px rgb(185 213 208 / 70%);
          // border: 1px solid rgba(134, 74, 255, 0.4);
          padding: 20px;
          border-radius: 10px;
          text-align: center;

          min-width: 300px;

          .icon {
            margin-bottom: 20px;

            img {
              width: 50px;
            }
          }

          h4 {
            color: #229a87;
            font-size: 1em;
            text-transform: uppercase;
            margin-bottom: 5px;
          }

          a,
          p {
            color: #333;
            font-weight: 400;
            margin-bottom: 0;
            font-size: 0.95em;
            line-height: 22px;
            min-height: 50px;
            display: block;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.contact-form {
  background: #f8f8f8;
  padding: 4em 0;

  .form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 5px 0px 20px rgb(185 213 208 / 70%);
    padding: 40px 30px;
    border-radius: 10px;

    .form-group {
      .ig-input {
        margin-bottom: 20px;
      }

      label {
        font-weight: 400;
        font-size: 14px;
        color: #6a6a6a;
      }

      .ig-form-input,
      select {
        height: 45px;
        border-radius: 5px;

        &::placeholder {
          font-size: 14px;
        }
      }

      textarea {
        height: 100px !important;
      }
    }
  }
}