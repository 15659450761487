#befits-page {
  .page-start {
    padding: 4em 0 0 0;
  }

  .bg-grey {
    background: #f9f9f9;
    padding: 4em 0;
  }

  .flow {
    padding: 4em 0 0 0;

    img {
      max-width: 80%;
      display: table;
      margin: 0 auto;
    }
  }

  .student {
    .image-block {
      img {
        width: 330px;
      }
    }
  }

  .teacher {
    .image-block {
      img {
        width: 400px;
      }
    }
  }

  .list {
    li {
      display: grid;
      grid-template-columns: 8px auto;
      column-gap: 10px;

      &:not(:last-child) {
        margin-bottom: 7px;
      }

      span {
        display: block;
        width: 8px;
        height: 8px;
        background: #229a87;
        line-height: 27px;
        margin-top: 9px;
      }

      p {
        margin: 0;
      }
    }
  }

  p {
    color: #333;
  }
}