.custom-table-responsive {
  border: 1px solid #a0e1cd;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 760px) {
    border: none !important;
    border-radius: 0;
    padding: 10px;
  }

  table {
    border-collapse: collapse;
    max-width: 100%;
    border: 1px solid #def9f1;
    border-radius: 5px;
    margin: 0;
  }

  th {
    font-size: 0.82rem;
    border-top: none;
    padding: 0.5rem 1em;
    background-color: #def9f1;
    border-bottom: 1px solid #def9f1;
    color: #565b5c;
    letter-spacing: 0.04rem;
    font-weight: 600;
  }

  td {
    font-size: 0.9em;
  }

  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {

    table {
      width: 100%;
      border: none;
    }
    .table tbody+tbody {
      border: 0;
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;

    }

    tr {
      border: 1px solid #ccc;
      margin: 10px 0;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      font-weight: 500;
    }
    td.pad-0 {
      padding: 10px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);
      font-weight: 500;
      color: #000;
    }

  }
}