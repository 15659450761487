$primary-color: #229a87;
$white-color: #ffffff;
$black-color: #000000;

.right-menu-list {
  list-style: none;
  padding: 10px 0px;
  margin: 0;
  background-color: #229a87;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
}

.right-menu-list li {
  padding: 2px 10px;
  width: 33%;
  text-align: center;
}

.right-menu-list li.bordered {
  border-right: 2px solid #fff;
}

.right-menu-list li a {
  color: #fff;
  font-size: 1rem;
}

.rmenu-skills-list {
 list-style: none;
 padding: 0;
 margin: 0;
 display: inline-block;
 max-height: 40vh;
 overflow-y: auto;
 @media (max-width: 991px) {
    max-height: 50vh;
 }
}

.rmenu-skills-list li {
  padding: 5px;
  font-size: .75rem;
  float: left;
  margin-right: 5px;
  border-left: 3px solid $primary-color;
  border-radius: 3px;
  background-color: #f4f4f4;
  color: $primary-color;
  margin-bottom: 10px;
}