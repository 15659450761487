$breakpoints: (xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl:2000px);

// @include media-breakpoint-above(sm) {}
@mixin media-breakpoint-up($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// @include custom breakpoint
@mixin custom-breakpoint-up($breakpoint) {
  @media screen and (min-width:$breakpoint) {
    @content;
  }
}

// @include media-breakpoint-below(sm) {}
@mixin media-breakpoint-down($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 0.02)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// @include custom breakpoint
@mixin custom-breakpoint-down($breakpoint) {
  @media screen and (max-width:$breakpoint - 0.02) {
    @content;
  }
}

// @include media-breakpoint-between(sm, md) {}
@mixin media-breakpoint-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 0.02)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  }

  @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower)==false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper)==false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

// @include custom breakpoint
@mixin custom-breakpoint-between($breakpoint, $breakpoint2) {
  @media screen and (min-width:$breakpoint) and (max-width: $breakpoint2 - 0.02) {
    @content;
  }
}

// How to use breakpoints
@include media-breakpoint-up(sm) {}

@include media-breakpoint-down(sm) {}

@include media-breakpoint-between(sm, lg) {}

@include custom-breakpoint-up(sm) {}

@include custom-breakpoint-down(sm) {}

@include custom-breakpoint-between(sm, lg) {}

// Font size & Line Height
@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {

  @each $fs-breakpoint,
  $fs-font-size in $fs-map {
    @if $fs-breakpoint==null {
      @include make-font-size($fs-font-size);
    }

    @else {

      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }

      @media(min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {

  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size)=="list" {
    font-size: nth($fs-font-size, 1);

    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }

  @else {
    font-size: $fs-font-size;
  }
}

// ! Font size mixin
// ! null is default value
$section-heading: ( //
  //
  null : 2.75em,
  xs : 1.1em,
  sm: 1.4em,
  md : 1.5em,
  lg : 1.75em,
  xl: 2em,
  xxl: 2.75em,
  xxxl: 3em,
);
// !Fonts size & Line Height mixin
// ! null is default value
$p-font-sizes: ( //
  //
  null : (2.75em, 1.3),
  xs : 1.1em,
  sm: 1.4em,
  md : 1.5em,
  lg : 1.75em,
  xl: 2em,
  xxl: 2.75em,
  xxxl: 3em,
);

// ! How to use this mixin
// @include font-size($h1-font-sizes);