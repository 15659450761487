#my-inbox {
  .page-name {
    font-size: 1.3em;
    color: #229a87;
    font-weight: 500;
  }

  .inbox-card {
    .inbox-header {
      margin-bottom: 20px;

      h4 {
        margin: 0;
        font-size: 1.1em;
      }

      button {
        margin-top: 10px;

        @media (min-width: 768px) {
          float: right;
          margin-top: 0;
        }
      }

      p {
        font-size: 13px;
        margin: 0;
        line-height: 20px;

        a {
          padding-left: 5px;
          color: #229a87;
        }
      }
    }
  }
}