.search-index {
    /* background-color: #037b61; */
    background-image: url('https://preview.colorlib.com/theme/webuni/img/bg.jpg.webp');
    background-size: cover;
    min-height: 100vh;
    color: #fff;
    padding: 5vh 10px;
}
.cat-index {
    padding: 20px;
}
.cat-list li {
    border: 1px solid #ddd;
    width: 30%;
    min-height: 50px;
    float: left;
    margin: 1%;
    text-align: center;
    padding: 10px;
}