.user-dashboard-logo {
  border-bottom: 5px solid #229a87;
  padding: 30px 15px;
}
.left-menu-list {
  list-style: none;
  padding: 0 0 20px;
  margin: 0;
  border-bottom: 3px solid #229a87;
  display: flow-root;
}
.left-menu-list li {
  padding: 0.7vw 5px;
}
.left-menu-list li a {
  color: #565b5c;
  font-size: 1.2rem;
}
.left-menu-list li a .fa {
  width: 25px;
}
.left-menu-list li a.active {
  color: #229a87;
}
.left-menu-c-block {
  padding: 10px 25px;
}
.left-menu-c-block.min {
  padding: 10px;
}
.left-menu-c-block.min .left-menu-list li a span {
  display: block;
  font-size: 0.7rem;
}
.left-menu-c-block.min .left-menu-list li {
  text-align: center;
  padding: 10px 0;
  width: 100%;
}
.min-view {
  max-width: 6.5%;
}
.max-view {
  max-width: 16%;
}
.left-menu-c-block.min .left-menu-list li a {
  font-size: 0.85rem;
  text-align: center;
  display: block;
}
