/* header */
.header-fixed {
  /* background-color: #fff; */
  /* border-bottom: 1px solid #f9ec3461; */
  height: 74px;
}
.header-light {
  background-color: transparent;
  border: none;
}
.header-light .navbar-light .navbar-nav .nav-item a {
  color: #fff;
}
.navbar-light .navbar-nav .nav-item a {
  color: #fff;
  font-weight: 500;
}
.navbar-light .navbar-nav .nav-item a:hover {
  color: #fff;
}
.navbar-light .navbar-nav .nav-item {
  padding-left: 10px;
}
.navbar-brand {
  max-width: 30vw;
}
.navbar-brand .header-logo {
  width: 180px;
}
@media (max-width: 576px) {
  .navbar-brand .header-logo {
    width: 130px;
  }
}
.child {
  background-image: url("../images/bg-modal.png");
}
