.each-announcement {

  .top {
    padding: 10px 20px;
    border-bottom: 1px solid rgb(238, 238, 238);

    @media (max-width: 576px) {
      padding: 10px 15px;
    }

    @media (max-width: 300px) {
      .col-6 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    h5 {
      margin-bottom: 0;
    }

    .info-block {
      p {
        line-height: 20px;
      }
    }
  }

  .middle {
    padding: 10px 5px;
    border-bottom: 1px solid rgb(238, 238, 238);

    @media (max-width: 300px) {
      .col-6 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .bottom {
    padding: 10px 20px;
  }
}

.tab-inner-title {
  display: flex;

  h4 {
    background-color: rgb(40, 116, 166);
    color: rgb(255, 255, 255);
    padding: 5px 10px;
    margin: 0px;
    font-size: 16px;

    @media (max-width: 576px) {
      font-size: 12px;
    }
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(249, 237, 53);
    color: rgb(0, 0, 0);
    padding: 5px 10px;
    margin: 0px;
    font-weight: 600;
  }
}