footer {
  padding: 2.5em 0 2em 0;
  background: #03205C;
  background-size: cover;
  background-position: top;
  position: relative;
  z-index: 1;

  .slider-shape {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    opacity: 0.15;
    z-index: -1;
    background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
  }


  a,
  p,
  h5 {
    color: #f5f5f5;
    font-weight: 400;
  }

  .each-footer-block {
    &.description {
      padding-right: 5em;

      .logo {
        margin-bottom: 15px;

        img {
          width: 200px;
        }
      }

      p {
        line-height: 25px;
        font-size: 0.9em;
      }
    }

    .footer-title {
      font-size: 1.2em;
      margin-bottom: 1em;
      color: #fff;
      font-weight: 500;
      letter-spacing: 0.3px;
      position: relative;
      text-shadow: 0 1px 2px #000;

      &.opacity-o {
        opacity: 0;

        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    .footer-menu {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &:not(:last-child) {
          margin-bottom: 3px;
        }

        a {
          font-size: 0.95em;
        }
      }
    }

    address {
      font-style: normal;

      .each {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      p,
      a {
        font-size: 0.9em;
      }

      h5 {
        font-size: 0.95em;
        margin-bottom: 5px;
        color: #f9ed35;
        font-weight: 400;
      }

      p {
        margin: 0;
      }

      a {
        display: block;
        line-height: 1.75;
      }
    }

    .social-links {
      justify-content: flex-start;

      li {
        &:not(:last-child) {
          margin-right: 18px;
        }

        span {
          font-size: 18px;
        }
      }
    }


  }
}