.primary-title {
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0;
}

.secondry-heading {
  font-size: 1.15em;
  font-weight: 600;
  letter-spacing: 0;
}

.green-c {
  color: #229a87;
}

.blue-c {
  color: #03205C;
}