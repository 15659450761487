#my-wallet {
  .main-title {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    margin-bottom: 1em;
    margin-top: 1.5em;
  }

  #add-coins {
    padding: 20px;
    border-radius: 10px;

    .title {
      font-size: 1.1em;
      color: #229A87;
      margin-bottom: 20px;
      font-weight: 500;
      letter-spacing: 0;

      span {
        float: right;
        font-weight: 600;
        font-size: 1em;
      }
    }

    .choose-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2.5em;

      @media (max-width: 991px) {
        margin-top: 1em;
      }

      li {
        button {
          width: 50px;
          background: #dc3545;
          border: none;
          color: #fff;
          font-weight: 600;
          font-size: 20px;
          height: 40px;
          padding: 0;

          &:focus {
            border: none;
            outline: none;
          }
        }

        &:nth-child(1) {
          button {
            border-radius: 5px 0 0 5px;
          }
        }

        &:nth-child(3) {
          button {
            border-radius: 0 5px 5px 0;
          }
        }

        p {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          min-width: 80px;
          text-align: center;
          background: #f5f5f5;
          height: 40px;
          display: flex;
          color: #333;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .coin-choose-block {
      @media (min-width: 1200px) {
        min-height: 400px;
        border-right: 1px solid rgb(221, 221, 221);
      }

      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
    }

    .use-checkbox {
      font-size: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      color: #6a6a6a;

      input {
        height: 17px;
        width: 17px;
        border: 1px solid #229A87;
        margin-right: 5px;
      }
    }

    .mini-title {
      font-size: 1.1em;
      color: #333;
      margin-bottom: 20px;
    }

    .add-remove-coin {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      li {
        button {
          width: 50px;
          background: #229A87;
          border: none;
          color: #fff;
          font-weight: 600;
          font-size: 20px;
          height: 35px;
          padding: 0;

          &:focus {
            border: none;
            outline: none;
          }
        }

        &:nth-child(1) {
          button {
            border-radius: 5px 0 0 5px;
          }
        }

        &:nth-child(3) {
          button {
            border-radius: 0 5px 5px 0;
          }
        }

        p {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          min-width: 80px;
          text-align: center;
          background: #f5f5f5;
          height: 35px;
          display: flex;
          color: #333;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .pricing-list {
      // background: #EDFAF6;

      li {
        display: flex;
        justify-content: space-between;

        &.coupon-list {
          display: block;
        }

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        p {
          margin: 0;
          font-size: 15px;
        }

        .name {
          min-width: 200px;
        }

        .value {
          color: #000;
          text-align: right;
          font-weight: 500;
          font-size: 16px;
        }

        .coupon-code-group {
          display: grid;
          grid-template-columns: auto 70px;
          // column-gap: 10px;
          max-width: 250px;
          margin: 0 0 0 auto;

          .ig-form-input {
            height: 34px;
            border-radius: 5px 0 0 5px;

            &::placeholder {
              font-size: 15px;
            }
          }

          .apply-button {
            height: 34px;
            background: #229A87;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 0 5px 5px 0;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;

            &:focus {
              border: none;
              outline: none;
            }
          }
        }

        &.total {
          border-top: 1px solid rgba(0, 0, 0, .1);
          padding-top: 10px;
          // background: #fff;
          // padding: 15px 20px;
          border-radius: 0;

          p {
            font-size: 15px;
            font-weight: 600;
            color: #000;
          }
        }
      }
    }

    .use-coupon {
      background: #229a87;
      padding: 3px 10px;
      border-radius: 5px;
      font-weight: 500;
      letter-spacing: 0;
      font-size: 13px;
      color: #fff;
      text-transform: uppercase;
    }

    .apply-btn {
      background: #229a87;
      padding: 2px 5px;
      border-radius: 3px;
      font-weight: 500;
      letter-spacing: 0;
      font-size: 13px;
      color: #fff;
      // text-transform: uppercase;
    }

    .coupon-block {
      display: flex;
      justify-content: flex-start;
      padding: 10px 0;

      &:hover {
        cursor: pointer;
      }

      .applied-coupon {
        padding: 3px 10px;
        background: #229A87;
        color: #fff;
        font-weight: 500;
        border-radius: 4px;
        letter-spacing: 0;
        font-size: 13px;
        text-transform: uppercase;
      }
    }

    .coupons-list {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
    }

    .continue-button {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      &.modal-continue-button {
        justify-content: center;
        margin-top: 2em;
      }

      button {
        background: #f9ed35;
        padding: 5px 5px 5px 25px;
        color: #000;
        font-weight: 500;
        border: none;
        margin-top: 1em;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 0.95em;
        letter-spacing: 0px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #ece033;
          z-index: -1;
          transform: translateX(-100%);
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          &:after {
            transform: translateX(0) !important;
          }
        }

        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }

        span {
          font-size: 1em;
          background: #fff;
          text-align: center;
          display: inline-block;
          padding: 5px 15px;
          margin-left: 10px;
        }
      }
    }
  }
}

.payment-block-modal {
  padding: 20px;

  .border-right {
    margin-bottom: 30px;

    @media (min-width: 991px) {
      border-right: 1px solid rgb(221, 221, 221);
      min-height: 400px;
      margin-bottom: 0;
    }
  }

  .title {
    font-size: 1.1em;
    color: #229A87;
    margin-bottom: 20px;
    font-weight: 500;
    letter-spacing: 0;

    span {
      float: right;
      font-weight: 600;
      font-size: 1em;
    }
  }

}