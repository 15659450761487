#terms-page {
  .inner {
    padding: 3em 0;
  }

  .main-heading {
    color: #229a87;
    font-size: 1.3em;
    margin-bottom: 1em;
  }

  .sub-heading {
    color: #229a87;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  p {
    font-size: 0.95em;
    line-height: 1.75;
    margin: 0;
    color: #333;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  ul {
    li {
      display: grid;
      grid-template-columns: 18px auto;
      column-gap: 10px;

      span {
        background: #229A87;
        color: #fff;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.75;
        padding: 4px;
        margin-top: 7px;
        font-size: 8px;
        border-radius: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }



    }
  }
}

#privacy-policy-page {
  .inner {
    padding: 3em 0;
  }

  .main-heading {
    color: #229a87;
    font-size: 1.3em;
    margin-bottom: 1em;
  }

  .sub-heading {
    color: #229a87;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  p {
    font-size: 0.95em;
    line-height: 1.75;
    margin: 0;
    color: #333;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
}