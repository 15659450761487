#dashboard-copyright {
  padding: 10px 1em;
  background: url("../../../src/images/slider_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    opacity: 0.2;
    z-index: -1;
    background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
  }

  .footer-app-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      &:not(:last-child) {
        margin-right: 15px;
      }

      img {
        max-width: 80px;
      }

      &:nth-child(1) {
        img {
          max-width: 90px;
        }
      }
    }
  }

  .copyright {

    p {
      font-size: .875rem;
      margin-bottom: 0;
      line-height: 1.5;
      color: #fff;

      span {
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }

  .quick-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      &:not(:last-child) {
        margin-right: 15px;
        position: relative;
      }

      a {
        font-size: .8125rem;
        color: #fff;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 1200px) {
    text-align: center;

    .quick-links,
    .social-links,
    .footer-app-links {
      justify-content: center;
      margin: 1px 0;
      flex-wrap: wrap;
    }
  }
}

#dashboard-navbar {
  background: url("../../../src/images/header-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 2em;
  position: relative;
  z-index: 9;
  position: fixed;
  left: 0;
  right: 0;
  padding: 10px 2em;

  @media (max-width: 991px) {
    padding: 5px 10px;
  }

  // position: relative;
  .inner {
    z-index: 1;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      opacity: 0.2;
      z-index: -1;
      background: url("../../../src/images/shapes/slider-shape.svg");
      background-size: 300px;
    }
  }

  .inner {
    @media (min-width: 1700px) {
      max-width: 1400px;
      margin: 0 auto;
    }
  }

  .toggle-navigation {
    display: flex;
    align-items: center;
    margin-top: 0;

    // @media (max-width: 991px) {
    //   justify-content: space-between;
    //   flex-direction: row-reverse;
    // }

    li {
      &:not(:last-child) {
        margin: 5px 15px 0 0;

        @media (max-width: 991px) {
          margin-right: 10px;
        }
      }

      .toggle-icon {
        font-size: 20px;
        list-style: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .m-version {
          display: none;
        }
      }

      i {
        font-size: 25px;
        transition: all 0.2s ease-in-out;
      }

      .logo {
        img {
          max-width: 140px;

          @media (max-width: 576px) {
            max-width: 120px;
          }
        }
      }
    }
  }

  .right-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      &:not(:last-child) {
        margin-right: 15px;

        @media (max-width: 991px) {
          margin-right: 5px;
        }
      }

      &.referal-link {
        span {
          color: #fff;
        }
      }

      .icon {
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;



        span {
          color: $primary-color;
          font-size: 15px;
        }

        @media (max-width: 576px) {
          width: 25px;
          height: 25px;

          span {
            font-size: 13px;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.dashboard-page {
  background: #edfaf6;
  position: relative;
  z-index: 1;
}

.dashboard-parts {
  @media (min-width: 991px) {
    grid-template-columns: 280px auto;
  }
}

.dashboard {
  display: grid;
  column-gap: 30px;
  // padding: 30px;
  background: #f2f2f2;
  position: relative;
  // z-index: 1;
  min-height: 100vh;
  padding-top: calc(60px + 30px) !important;
  padding-bottom: 30px;

  // background: url("../../../src/images/background-bg.svg");
  @media (max-width: 991px) {
    padding: 20px 15px 50px;
    padding-top: 90px !important;
    display: block;
    background: #fff;

    &:after {
      background-size: cover;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    opacity: 0.6;
    z-index: -1;
    background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
  }

  @media (min-width: 1700px) {
    max-width: 1400px;
    margin: 0 auto;
  }

  @media (max-width: 576px) {
    padding: 10px;
    // padding-top: 120px !important;
    padding-top: 65px !important;
  }

  .left-block {
    // background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
    box-shadow: none;
    border-radius: 0;
    padding: 20px 20px;
    position: relative;
    box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
    border-radius: 10px;
    position: relative;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;

    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      width: 75%;
      max-width: 400px;
      background: #fff;
      z-index: 99999999;
      transition: all 0.5s ease-in-out;
      overflow-y: scroll;
      padding: 20px 10px;
      border-radius: 0;
    }

    .profile-card {
      .title {
        color: $primary-color;
        font-weight: 600;
        text-align: center;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .user-img {
        border-radius: 15px;
        border: 2px solid $primary-color;
        padding: 2px;

        img {
          border-radius: 15px;
        }

        .user-img-layer {
          text-align: center;
          padding: 5px 10px;
          font-weight: 600;
          text-transform: uppercase;

          span {
            font-size: 11px;
            padding-right: 2px;
          }
        }
      }

      .details {
        text-align: center;

        .name {
          font-size: 1.05em;
          color: $primary-color;
          text-transform: capitalize;
          margin-bottom: 5px;
          line-height: 15px;
        }

        .plan {
          box-shadow: 0 0 2px 2px #e1fffa;
          color: #229a87;
          background: #e1efef;
          display: inline-block;
          padding: 5px 10px;
          margin-top: 5px;
          border-radius: 5px;
          font-size: .9375rem;
        }

        .phone-verify {
          color: $primary-color;
          margin-top: 15px;

          .icon {
            padding-left: 10px;
            display: inline-block;
            margin-top: -3px;
          }
        }
      }
    }

    .rating-block {
      margin: 30px 0;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: -15px;
        background: rgba(34, 136, 120, 0.1);
        height: 1px;
        // width: 100%;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          &:not(:last-child) {
            margin-right: 5px;
          }

          &.count {
            background: $primary-color;
            min-width: 25px;
            height: 25px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            font-weight: 600;
            padding: 0 9px;
            font-size: 13px;
          }

          &.current-count {
            color: $primary-color;
            font-weight: 600;
          }
        }
      }
    }

    .credits {
      margin: 30px 0;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: -15px;
        background: rgba(34, 136, 120, 0.1);
        height: 1px;
        // width: 100%;
      }

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
          h3 {
            font-size: 0.8em;
            color: $primary-color;
            margin-bottom: 0;
            font-weight: 600;
            text-transform: uppercase;
          }

          .creadit-button {
            background: $primary-color;
            color: #fff;
            padding: 1px 10px;
            border-radius: 5px;
            border: none;
            font-weight: 600;

            img {
              padding-right: 5px;
              width: 20px;
            }
          }
        }
      }
    }

    .wishlist {
      margin: 30px 0;
      position: relative;

      >ul {
        display: flex;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-right: 5px;
          }

          &:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
          }

          &.name {
            font-size: 0.8em;
            color: $primary-color;
            margin-bottom: 0;
            font-weight: 600;
            text-transform: uppercase;
            white-space: pre;
          }

          .edit-link {
            background: #dc3545;
            padding: 2px 10px;
            color: #fff;
            font-weight: 500;
            border-radius: 5px;
            font-size: 13px;
            display: flex;
            align-items: center;

            span {
              padding-left: 5px;
              font-size: 10px;
            }
          }
        }
      }
    }

    .courses {
      margin-top: 15px;

      .rmenu-skills-list li {
        font-weight: 500;
        padding: 5px 7px;
        font-size: .75rem;
      }
    }
  }

  .right-block {
    // background: rgba(255, 255, 255, 0.1);
    box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.5);

    .border-card {
      border: 1px solid rgba(34, 136, 120, 0.2);
      box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.3);
      border-radius: 10px;
    }

    .find-teacher {
      .title {
        font-size: 1em;
        color: $primary-color;
        margin-bottom: 0;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(185, 213, 208, 0.3);
        padding-bottom: 5px;
      }

      .sub-title {
        font-size: 1em;
        color: #6a6a6a;
        margin-bottom: 0;
        font-weight: 600;
      }

      .input-element {
        margin-bottom: 15px;

        input {
          height: 35px;
          max-width: 300px;
          border: 1px solid rgba(185, 213, 208, 0.7);
          font-size: 14px;

          &::placeholder {
            font-size: 0.9em;
          }
        }
      }

      .filters-group {
        margin-top: 20px;

        >ul {
          display: flex;
          flex-wrap: wrap;

          li {
            &:not(:last-child) {
              margin-right: 20px;
            }

            .f-right {
              height: 35px;
              line-height: 23px;
              font-weight: 600;
              color: $primary-color;
              font-size: 18px;
            }

            .ig-input-label {
              color: $primary-color;
              font-weight: 500;
              font-size: 0.8em;
            }

            .ig-input-select,
            .ig-form-input {
              min-width: 150px;
              height: 35px;
              border: 1px solid rgba(185, 213, 208, 0.7);
              font-size: 14px;

              &::placeholder {
                font-size: 0.9em;
              }
            }
          }
        }
      }

      .near-info {
        font-size: 14px;
        display: flex;
        align-items: center;

        input {
          margin-right: 10px;
        }
      }

      .action-buttons {
        ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          li {
            &:not(:last-child) {
              margin-right: 15px;
            }

            button {
              min-width: 80px;
              padding: 4px 0;
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid rgba(185, 213, 208, 0.5);
              text-transform: uppercase;
              font-weight: 500;
              font-size: 14px;
            }

            button:disabled,
            button[disabled] {
              opacity: 0.7;
            }

            .find-button {
              background: $primary-color;
              color: #fff;

              span {
                font-size: 12px;
                padding-right: 3px;
              }
            }

            .reset-button {
              background: #dc3545;
              color: #fff;

              span {
                font-size: 12px;
                padding-left: 5px;
                display: inline-block;
                margin-top: -3px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-tabs {
  @media (min-width:991px) {
    display: none;
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  // position: absolute;
  // bottom: 40px;
  // left: 50%;
  // transform: translateX(-50%);
  // margin-top: 20px;

  li {
    &:not(:last-child) {
      margin-right: 15px;
    }

    a {
      font-size: 16px;
      // line-height: 30px;
      // background: $primary-color;
      // width: 20px;
      // height: 20px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // border-radius: 3px;
      color: #fff;
      // border: 1px solid #fff;
    }
  }
}

.btn-yellow {
  font-weight: 500 !important;
}

.go-to-board {
  width: 100%;
  box-shadow: 5px 0px 30px rgb(185 213 208 / 50%);
  padding: 30px 15px;
  border: 1px solid rgba(134, 74, 255, 0.4);
  border-radius: 10px;
  position: relative;
  margin-top: 3em;

  @media (max-width: 991px) {
    padding: 30px 10px 10px;
    margin-top: 1em;
  }

  .shape {
    position: absolute;
    top: -25px;

    img {
      max-width: 60px;
    }
  }
}



.top-menu-nav {
  margin-bottom: 20px !important;

  i {
    padding-right: 5px;
  }

  li {
    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #000;
    }
  }
}

.enrollment-view {
  position: relative;
  margin-top: 0.5em;

  .title-block {
    .title {
      font-size: 1.2em;
      color: $primary-color;
      line-height: 22px;
      margin-bottom: 20px;
      letter-spacing: 0;

      svg {
        width: 18px;
        margin-right: 5px;
      }
    }
  }
}

.enrollments-list-group {
  border: 1px solid rgba(34, 136, 120, 0.4);
  border-radius: 10px;

  >div {
    padding: 10px 15px;

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(34, 136, 120, 0.4);
    }
  }


  .enrollments-data {
    // overflow: hidden;
    border-radius: 10px;

    .enrolled-date {
      // color: #229a87;
      color: #999;
      margin-bottom: 0;
      font-size: 0.75em;
      font-weight: 500;
    }

    label {
      color: $primary-color;
      border-radius: 10px;
      font-weight: 600;
      font-size: 15px;
      display: inline-block;
      margin-bottom: 5px;
    }

    p {
      font-size: 0.95em;
      color: #333;
      line-height: 1.5;
    }
  }
}

.enquiry-user-details {
  margin-bottom: 2em;
  // background: #EDFAF6;
  // padding: 20px;
  // border-radius: 10px;

  .inner-navbar {
    border-bottom: 1px solid rgba(185, 213, 208, 0.3);
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: $primary-color;
      font-size: 1em;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0;
      padding-bottom: 0;
      margin-bottom: 0;

      span {
        padding-right: 5px;
      }
    }

    .back-navigation {
      background: $primary-color;
      color: #fff;
      padding: 4px 18px;
      border-radius: 30px;
      font-size: 0.9em;
      font-weight: 500;

      span {
        padding-right: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.course-details {
  // box-shadow: 5px 0px 30px rgb(185 213 208 / 50%);
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0;

  .title {
    color: $primary-color;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0;
    padding: 10px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(185, 213, 208, 0.5);
    background: #f5f5f5;
    border-radius: 0;

    span {
      padding-right: 5px;
    }
  }
}

.feedback-rating-list {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  background: #f5f5f5;
  max-width: 400px;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 10px;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .name {
      color: #000;
    }

    .star-rating {
      min-width: 170px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    button {
      padding: 0 4px;
    }

    &.total-stars {
      .star-rating {
        button {
          color: #ccc;

          span {
            // color: #ccc !important;
            font-size: 1.1em !important;
          }
        }

        span {
          // color: #000;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }

  .submit-button {
    background: #f9ec35;
    color: #333;
    font-weight: 500;
    border: none;
    padding: 5px 15px;
    font-size: 0.9em;
    border-radius: 5px;
    margin-top: 10px;

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.rating-details {
  .star-rating {
    @media (max-width: 991px) {
      min-width: 170px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.feedback-rating-total {
  background: #f8f8f8;
  margin-bottom: 30px;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 10px;

  p {
    margin: 0;

    &.name {
      font-size: 0.9em;
      margin-bottom: 0px !important;
    }

    &.value {
      font-size: 16px;
      font-weight: 800;
    }
  }
}

.membership-plans-prices {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  >li {
    width: 32%;
    border-radius: 10px;
    background: #fff;
    border: 1px solid rgba(185, 213, 208, 0.3);

    &:nth-child(1) {

      .plan-validity,
      .membership-plan-footer,
      .membership-plan-body h2 {
        display: none;
      }

      .plan-features {
        margin-top: 0 !important;
      }

    }


    .membership-plan-header {
      background: linear-gradient(100deg, #229a87 55%, #86c9be);
      border-radius: 10px 10px 0 0;
      text-align: center;
      min-height: 120px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-bottom: 10px;
      }

      .plan-name {
        font-weight: 600;
        font-size: 1.3em;
        margin-bottom: 0;
      }

      .plan-validity {
        font-size: 1em;
        font-weight: 600;
        color: #f9ed35;
      }
    }

    .membership-plan-body {
      padding: 10px 10px;
      text-align: center;

      .current-plan {
        font-weight: 600;
        padding: 5px 10px;
      }

      .pricing {
        font-size: 20px;
        font-weight: 600;
      }

      .coins-price {
        font-size: 20px;
        font-weight: 600;
      }

      .plan-features {
        padding-left: 10px;
        margin-top: 20px;

        li {
          text-align: left;
          display: grid;
          grid-template-columns: 10px auto;
          column-gap: 7px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          span {
            line-height: 25px;
            font-size: 12px;
            color: #229a87;
          }

          p {
            margin: 0;
            font-size: 0.95em;
            color: #333;
            line-height: 25px;
            font-weight: 400;
          }
        }
      }
    }

    .membership-plan-footer {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .go-button {
        background: #f9ed35;
        color: #333;
        font-weight: 600;
        padding: 5px 20px;
        border: none;
        border-radius: 30px;
        min-width: 120px;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:focus {
          border: none;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      width: 48%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin: 10px 0;

      .membership-plan-header {
        min-height: auto;
        padding: 10px 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .mobile-toggle-cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(185, 213, 208, 0.1);
    z-index: -1;
  }
}

.enquiry-list-desc {
  padding-top: 5px;
  line-height: 1.2em;

  @media (max-width: 991px) {
    margin-bottom: 0;
  }
}

.page-not-found {
  * {
    transition: all 0.6s;
  }

  a {
    background: #229a87;
    border-radius: 30px;
    padding: 5px 25px;
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    margin-top: 20px;
  }

  #main {
    display: table;
    width: 100%;
    height: 100vh;
    text-align: center;
  }

  .fof {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .fof h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
    animation: type .5s alternate infinite;
  }

  @keyframes type {
    from {
      box-shadow: inset -3px 0px 0px #888;
    }

    to {
      box-shadow: inset -3px 0px 0px transparent;
    }
  }
}