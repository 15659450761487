#hero-slider {
  background: url("../../../src/images/slider_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  // padding: 4em 4em 1em 4em;
  // border-radius: 0 0 0 100px;
  // padding-top: 100px !important;
  padding-top: 150px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .slider-shape {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    opacity: 0.2;
    z-index: -1;
    background: url("../../../src/images/shapes/slider-shape.svg");
    background-size: 300px;
  }

  .slider-grid {
    display: grid;
    grid-template-columns: auto 450px;
    column-gap: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      text-align: center;

      .image-block {
        display: none;
      }

      .search-input,
      .join-now {
        margin: 5px auto !important;
      }
    }

    .content-block {

      .heading {
        margin-bottom: 2em;

        img {
          margin-bottom: 15px;
        }

        h1 {
          font-size: 1.75em;
          font-weight: 800;
          line-height: 1.2;
          margin-bottom: 10px;
        }

        .description {
          font-size: 0.95em;

        }
      }

      .search-input {
        margin-bottom: 0px;
        position: relative;
        max-width: 400px;

        input {
          border-radius: 30px;
          max-width: 400px;
          height: 45px;
          color: #fff;
          font-size: 15px;
          padding: 0 15px;
          border: 1px solid #375d89;
          background: #23446a;

          &::placeholder {
            font-size: 13px;
            color: #f5f5f5 !important;
          }

          &:focus {
            border-color: #229a87;
          }
        }

        .ig-input-frame {
          max-width: 400px;
          position: relative;
        }

        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          opacity: 0.4;
        }
      }

      .join-now {
        max-width: 400px;
        text-align: center;

        .heading {
          margin-bottom: 1.5em;
        }

        img {
          margin-bottom: 0;
        }

        h2 {
          font-size: 1.1em;
          font-weight: 600;
          letter-spacing: 0;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        p {
          font-size: 0.9em;
          margin-bottom: 0;
        }



        .action-buttons {
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          list-style: none;

          li {
            img {
              max-width: 60px;
              margin-top: -5px;
            }

            button {
              padding: 6px 20px;
              border-radius: 30px;
              border: none;
              background: #f9ed35;
              margin: 0 5px;
              font-size: 0.9em;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.1px;
            }
          }
        }
      }
    }

    .left-image {
      margin-left: -40px;
    }

    .right-image {
      margin-right: -40px;
    }
  }

}