.dropbtn {
  font-size: 16px;
  border: none;
  cursor: pointer;
  padding: 0.5em;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-item {
  color: #333 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}