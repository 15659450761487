@import "../scss/partials/mixins.scss";
@import "../scss/partials/colors";

@font-face {
  font-family: "Segoe";
  src: local("Segoe"), url("/fonts/segoe-ui/Segoe-UI.ttf") format("opentype");
}

@font-face {
  font-family: "Segoe Bold";
  src: local("Segoe"),
    url("/fonts/segoe-ui/Segoe-UI-Bold.ttf") format("opentype");
}

html {
  font-size: 16px;
}

body {
  font-size: 1em;
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6a6a6a;
}

p {
  line-height: 28px;
}

.inline-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.txt-bold {
  font-family: "Poppins Bold";
}

.header-fixed {
  position: fixed;
  top: -1px;
  left: 0%;
  right: 0%;
  z-index: 100;
  color: #fff;

  @media (max-width: 991px) {
    height: auto !important;
    background: url("../../src/images/slider_bg.svg");
    background-repeat: no-repeat;

    .inner {
      position: relative;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        max-width: 100%;
        opacity: 0.3;
        z-index: -1;
        background: url("../../src/images/shapes/slider-shape.svg");
        background-size: 300px;
      }
    }
  }

  a {
    color: #fff;
  }
}

h1 {
  font-size: 2.5rem;
  line-height: 3.4rem;
  letter-spacing: 0.3px;
}

h2 {
  font-size: 1.675rem;
  line-height: 1.65rem;
  letter-spacing: 0.3px;
}

h3 {
  font-size: 1.4125rem;
  line-height: 1.55rem;
  letter-spacing: 0.3px;
}

h4 {
  font-size: 1.15rem;
  line-height: 1.5rem;
  letter-spacing: 0.3px;
}

h5 {
  font-size: 1rem;
  line-height: 1.1rem;
}

h6 {
  font-size: 0.9rem;
  line-height: 1rem;
}

span {
  font-size: 0.85em;
}

a {
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
  }
}

.list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flow-root;
}

.list-f-left {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flow-root;

  li {
    float: left;
  }
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.justify-right {
  justify-content: right;
}

.d-flex-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-center {
  display: table;
  margin: 0 auto;
}

.max-fit-content {
  max-width: fit-content;
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.bolder {
  font-weight: 900;
}

.extra-bold {
  font-weight: 800;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

.extra-light {
  font-weight: 200;
}

.ls-big {
  letter-spacing: 2px;
}

.ls-small {
  letter-spacing: 1px;
}

.c-pointer {
  cursor: pointer;
}

.txt-capitalize {
  text-transform: capitalize;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-underline {
  text-decoration: underline;
}

.txt-strike {
  text-decoration: line-through;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.base-page-block {
  background-color: #fff;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  min-height: 81vh;
}

.info-block {
  margin-bottom: 15px;

  label {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: #888;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0;
    color: #000;
  }
}

.enquiry {
  font-size: 0.75rem;
  border: 1px solid #ddd;
  padding: 4px;

  .left {
    padding: 1px 4px;
    letter-spacing: 0.4px;
  }

  .right {
    padding: 1px 4px;
    letter-spacing: 0.4px;
    font-weight: 600;
  }
}

.txt-theme {
  color: $primary-color;
  font-weight: 600;
}

.txt-theme-light {
  color: #86c9be;
}

.txt-yellow {
  color: #f9ed35;
}

.txt-theme-link {
  color: #229a87;
  font-size: inherit;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #229a87;
    font-size: inherit;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
}

.txt-black {
  color: #000;
}

.txt-white {
  color: #fff;
}

.txt-grey {
  color: #c4c4c4;
}

.bg-theme {
  background-color: #229a87;
  color: #fff;
}

.bg-theme-gradient {
  background-color: #229a87;
}

.bg-content {
  background-color: #f4f4f4;
}

.bg-theme-light {
  background-color: #86c9be;
}

.banner-block {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.banner-block-light {
  background-color: #edfaf6;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
}

.bg-block-light {
  background-color: #edfaf6;
}

.shadow-theme {
  box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
  box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
  border-radius: 10px;
}

@media (max-width: 576px) {
  .enquiriesListEstimatedWidthClass {
    width: 50% !important;
  }
}

.info-snip {
  margin-bottom: 10px;

  label {
    font-size: 0.9rem;
    margin: 0;
    color: #6a6a6a;
    font-weight: 500;
    padding-bottom: 0;
  }

  p {
    font-size: 0.85rem;
    margin: 0;
    font-weight: 500;
    color: #333;
    line-height: 24px;
    word-wrap: break-word;
  }
}

.content-bg-block {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}

.bg-banner-round {
  background-color: rgba(13, 134, 172, 15%);
  border-radius: 20px;
  overflow: hidden;
}

.user-tab-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: inline-block;
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 10px;

  li {
    font-size: 1rem;
    border-bottom: 4px solid transparent;
    padding: 5px 10px 10px 0;
    float: left;
    font-weight: 500;
    margin-right: 15px;
    cursor: pointer;

    a {
      color: inherit;
    }
  }

  li.active {
    color: #229a87;
    border-color: #229a87;
  }
}

.app-main-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  // border-bottom: 1px solid #36a593;
  margin-bottom: 10px;
  // background: #f2f2f2;
  // padding: 5px 10px;
  border-radius: 10px;

  li {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-size: 1rem;
    border-bottom: 4px solid transparent;
    padding: 5px 10px 10px 0;
    float: left;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    color: #000;

    a {
      color: inherit;
    }
  }

  li.active {
    color: #229a87;
    border-color: #229a87;
  }
}

.table-theme {
  border-radius: 10px;
  overflow: scroll;
  padding: 0;
  border: 1px solid #def9f1;

  .table {
    thead {
      th {
        font-size: 0.8rem;
        border-top: none;
        padding: 0.7rem 1em;
        background-color: #def9f1;
        border-bottom: 1px solid #def9f1;
        color: #565b5c;
        letter-spacing: 0.04rem;
        font-weight: 600;
      }
    }

    tbody {
      td {
        font-size: 0.8rem;
        color: #565b5c;
        padding: 0.75rem 1em;
        border-color: #d6e4e0;
      }
    }
  }
}

.btn {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }
}

.btn-size-theme {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.0142658px;
  padding: 10px;
}

.btn-theme {
  background-color: $primary-color;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-yellow {
  background-color: #f9ed35;
  color: #282929;
  font-weight: 600;

  &:hover {
    color: #282929;
  }
}

.btn-white-plain {
  background-color: transparent;
  color: #fff;
  font-weight: 600;

  &:hover {
    color: #fff;
  }
}

.btn-underline {
  text-decoration: underline;
  color: #229a87;
  font-weight: 500;
  cursor: pointer;
}

.btn-circle {
  text-decoration: underline;
  color: #229a87;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #229a87;
  padding: 10px 14px;
  border-radius: 50%;
}

.plain-border-bottom {
  border-bottom: 1px solid #ddd;
}

.border-block {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.border-top {
  border-top: 1px solid #ddd;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.experiences-list {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    width: 300;
    height: 150px;
  }
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 50vw;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 6px 10px #ddd;
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  .inline-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flow-root;

    li {
      color: #000;
      width: 100%;
      cursor: pointer;

      span {
        width: 100%;
        padding: 4px 10px;
        display: block;
      }

      a {
        width: 100%;
        padding: 4px 10px;
        display: block;
      }

      &:hover {
        background-color: #c0c0c0;
      }
    }
  }
}
@media (max-width: 787.98px) {
  .search-results {
    max-width: 100%;
  }
}

@media (max-width: 787.98px) {
  .search-results {
    max-width: 100%;
  }
}

.search-results-tags {
  .tags-list {
    li {
      float: left;
      margin: 10px 10px 0 0;
      border: 1px solid #ddd;
      padding: 5px 10px;
      border-radius: 30px;
      font-size: 14px;
      cursor: pointer;
    }

    li.selected {
      border-color: #0986ab;
      color: #0986ab;
    }
  }
}

.enrollment-list {
  max-height: 40vh;
  overflow-y: auto;

  li {
    margin-bottom: 10px;

    h4 {
      font-size: 0.95rem;
      color: #229a87;
      margin-bottom: 0px;
      font-weight: bold;
    }

    h5 {
      font-size: 0.9rem;
      margin-bottom: 0px;
      color: #000;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 0px;
    }
  }
}

.user-img {
  position: relative;
  max-width: 100px;
  display: table;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #ddd;

  @media (max-width: 991px) {
    max-width: 40px;
  }

  &:hover {
    .user-img-layer {
      display: block;
    }
  }
}

.user-img-layer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 5px;
  background-color: rgba(255, 255, 255, 1);
  font-size: 12px;
  cursor: pointer;
  display: none;
  color: #229a87;
}

.social-links-browse {
  li {
    float: left;
    padding: 0 10px 0 0;
  }
}

.user-footer-links {
  li {
    float: left;
    padding: 10px 10px 5px;
    color: #999;

    a {
      color: #444;
      font-size: 12px;
    }
  }
}

.user-footer-links.text-right {
  li {
    float: right;
  }
}

.ratings-head {
  position: relative;

  &:hover {
    .rating-details {
      display: block;
    }
  }
}

.rating-details {
  position: absolute;
  border: 1px solid #ddd;
  top: 100%;
  left: 0;
  width: max-content;
  background-color: #fff;
  z-index: 10000;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 5px 5px #ddd;
  display: none;

  @media (max-width: 768px) {
    width: auto;
    left: -50px;

    .star-rating>.name {
      display: block;
      width: 100%;

    }

  }

  &:hover {
    display: block;
  }
}

.gtp-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input {
    &:checked+.slider {
      background-color: #229a87;

      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    &:focus+.slider {
      box-shadow: 0 0 1px #229a87;
    }
  }

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}

.d-mobile-block {
  display: none;
}

.d-tablet-block {
  display: none;
}

.d-laptop-block {
  display: none;
}

.d-large-display-block {
  display: none;
}

.mobile-navigation-menu {
  background: #fff;
  color: #000;
  border-top: 1px solid rgba(185, 213, 208, 0.9);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999999;
  padding: 0px 5px;

  .user-mobile-tabs {
    background-color: #fff;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: #229a87;
    }
  }

  .user-mobile-tabs-menu {
    list-style: none;
    margin: 0;
    padding: 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    li {
      // min-width: 100px;
      // width: 100px;

      a {
        color: #000;
        text-align: center;
        font-size: 12px;
        padding: 10px 0;

        &.active {
          color: #229a87;

          span,
          i {
            color: #229a87!important;
          }

          a {
            color: #229a87!important;
            font-weight: 600;
          }
        }

        span {
          display: block;
          text-align: center;
          padding: 0;
          letter-spacing: 0.4px;
          font-size: 12px;

          &:nth-child(2) {
            color: #666;
          }
        }
      }

      i {
        font-size: 14px;
        color: #666;
      }

      span {
        display: block;
        text-align: center;
        padding: 0;
        letter-spacing: 0.4px;
        font-size: 12px;
      }
    }
  }
}




.reponsive-enquires-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flow-root;

  li {
    background-color: #fff;
    padding: 0px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 2px;

    h5 {
      font-weight: bold;
    }

    .info-block {
      margin: 0;

      p {
        margin: 0;
        font-size: 0.85rem;
      }

      label {
        margin: 0;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}


.user-header-bg {
  background: url("../../src/images/slider_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;

  // background-size: 300px;
  .inner {
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      opacity: 0.2;
      z-index: -1;
      background: url("../../src/images/shapes/slider-shape.svg");
      background-size: 300px;
    }
  }
}

.navbar {
  border: none;

  @media (max-width: 991px) {
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      left: -200px;
      right: -200px;
      top: 0;
      bottom: 0;
      // background: #1c8580;
      z-index: -1;
    }

    .navbar-toggler {
      background: #fff;
    }
  }
}

.overlap-block {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding-right: 40%;
}

.enquiry-questions-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;

    label {
      font-weight: bold;
      font-size: 0.8rem;
      margin: 0;
    }

    p {
      font-size: 0.7rem;
      margin: 0;
      font-weight: 400;
    }
  }
}

.enquiry-responds-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 10px;

    label {
      font-weight: bold;
      font-size: 0.8rem;
      margin: 0;
    }

    p {
      font-size: 0.7rem;
      margin: 0;
      font-weight: 400;
    }
  }
}

.menu-list-top {
  li {
    float: right;
    padding: 0px 5px;

    a {
      padding: 0px;
    }
  }
}

.ico-user-menu {
  max-height: 30px;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
}

// .notifications-head {
//   position: relative;

//   &:hover {
//     .notifications-block {
//       display: block;
//     }
//   }
// }

// .user-menu-block-head {
//   position: relative;

//   &:hover {
//     .user-menu-block {
//       display: block;
//     }
//   }
// }

// .notifications-block {
//   position: absolute;
//   right: -5px;
//   top: 100%;
//   z-index: 1000;
//   display: none;
//   padding-top: 45px;

//   &:hover {
//     display: block;
//   }
// }

.notification-dropdown {
  .dropdown-menu {
    left: -19vw;
    background: none;
    padding: 0;
    margin: 0;
    min-width: 300px;
  }

  .notification-card {
    min-height: 200px;
    min-width: 250px;
    width: 330px;
    background: #fff;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    box-shadow: 0px 0px 5px #999;
    text-align: left;
    overflow: scroll;
    padding: 20px;

    @media (max-width: 350px) {
      width: 100%;
    }

    h6 {
      padding-bottom: 10px;
    }

    .alert-content {
      padding-left: 20px;

      label {
        padding-bottom: 5px;
      }
    }

    .alerts-list li .alert-content p {
      white-space: initial;
      line-height: 16px;
    }
  }
}

.profile-dropdown {
  .btn-primary {
    background: none;
    padding: 0;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .dropdown-item {
    margin-right: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    text-align: left;

    a,
    .logout-menu {
      color: #333;
      font-size: 15px;
      padding: 7px 15px;
      display: block;
      text-align: left;

      span {
        padding-right: 5px !important;
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    left: -8vw;
    padding: 0;
    margin: 0;

    li {
      &:hover {
        background: #f2f2f2;
      }

      @mixin po {
        color: #333;
        font-size: 15px;
        padding: 7px 15px;
        display: block;

        span {
          padding-right: 5px;
        }
      }

      &:not(:last-child) {
        margin-right: 0 !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        a {
          @include po;
        }
      }

      .logout-menu {
        @include po;

        &:hover {
          cursor: pointer;
        }

        .c-pointer {
          padding-right: 5px !important;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.h-100 {
  height: 100%;
}

.right-content-view-scroll {
  width: 100%;
  padding: 30px 20px;

  @media (max-width: 991px) {
    padding: 15px;
  }

  @media (max-width: 576px) {
    padding: 10px;
  }
}


.cursor {
  &:hover {
    cursor: pointer;
  }
}

.notifications-block-content {
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #999;
  width: 22vw;
  text-align: left;
  overflow: hidden;

  .noty-head {
    padding: 10px;
    background-color: #eee;
    color: #229a87;

    h4 {
      margin: 0;
    }
  }

  .noty-body {
    min-height: 40vh;
  }
}

.alerts-body {
  text-align: left;
  padding: 10px 10px 0;
}

.alerts-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 40vh;
  overflow-y: auto;

  li {
    display: flex;
    margin-bottom: 10px;

    .alert-img {
      max-width: 10%;
      min-width: 10%;
    }

    .alert-content {
      padding: 0px 5px;

      label {
        font-weight: 500;
        font-size: 0.8rem;
        margin-bottom: 0;
        letter-spacing: 0.5px;
      }

      p {
        font-size: 0.7rem;
        color: #333333;
        margin-bottom: 0;
        letter-spacing: 0.5px;
      }
    }
  }
}

.user-menu-block {
  position: absolute;
  right: -5px;
  top: 100%;
  z-index: 1000;
  display: none;
  padding-top: 45px;
}

.user-menu-block-content {
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #999;
  width: max-content;
  text-align: left;
  overflow: hidden;
  padding: 5px 10px;

  .list-inline {
    li {
      float: inherit;
      width: max-content;
      padding: 8px 14px;

      a {
        color: #000;
        font-size: 0.9rem;
      }

      span {
        color: #000;
        font-size: 0.9rem;
      }
    }
  }
}

.user-dashboard-banner {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
}

.dashboard-menu {
  li {
    padding: 5px 10px 5px 15px;
    cursor: pointer;
    font-size: 500;
    margin-bottom: 10px;
  }

  li.active {
    background-image: url("../images//Vector.png");
    // background-image: url("../images/Vector.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.user-menu {
  li {
    padding: 40px 10px 10px;
    float: left;
    min-width: 130px;

    a {
      color: #c1865c;
      text-decoration: none;
      font-weight: bold;
      font-size: 1.05em;
      letter-spacing: 0.4px;
    }
  }
}

.bg-linear {
  background: #229a87;
  background: linear-gradient(100deg, #229a87 55%, #86c9be);
}

.index-block {
  max-height: 55vh;
  color: #fff;
  padding-top: 2vh;

  .rpb {
    padding-top: 8vh;
  }

  .btn-size-theme {
    font-size: 1.25rem;
    padding: 0.7rem 2rem;
  }
}

.home-content-block {
  padding: 8vh 0 5vh;
}

.home-search {
  .ig-input-frame {
    input {
      padding: 1.5rem 1rem !important;
    }
  }
}

.bg-mathrix {
  background-image: url("../images/mathrix-bg.png");
  background-size: contain;
}

.bg-vector {
  background-image: url("../images/Vector.png");
  background-repeat: no-repeat;
  display: inline;
  padding-right: 35px;
  margin-bottom: 10px;
}

.bg-vector-label {
  background-image: url("../images/Vector.png");
  background-repeat: no-repeat;
  display: inline;
  background-size: contain;
}

.theme-badge {
  padding: 1px 10px;
  background-color: $primary-color;
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
  position: relative;
  top: -1px;
  margin-left: 5px;
  box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
}

.enquiry-list-block {
  @media (max-width: 576px) {
    h4 {
      font-size: 15px;
    }
  }

  .user-tab-menu {
    li {
      &:nth-child(2) {
        font-style: italic;
        font-weight: 500;
      }

      font-size: 0.9rem;
      padding: 5px 10px 5px 0;
    }
  }
}

.gt-progess-bar {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  background-color: #fdf6cf;
  border-radius: 10px;
  overflow: hidden;
  height: 12px;
}

.gt-progress-bar-process {
  background: #f9ed35;
}

.user-tile {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.user-tile-img {
  padding: 10px;

  img {
    max-height: 100px;
    border-radius: 4px;
    max-width: 100px;
    border: 1px solid #ddd;
    display: table;
    margin: 0 auto;
  }
}

.gt-status {
  padding: 10px 15px;
  background-color: #aaf5de;
  display: inline-block;
  border-radius: 30px 0 0 30px;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  justify-content: end;
  max-width: fit-content;

  .check-block {
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
}

.gt-status-orange {
  background-color: #fef6f4;
  color: #ff7f2d;
}

.gt-status-danger {
  background-color: #fef4f4;
  color: #ff2d2d;
}

.pac-container {
  z-index: 1070;
}

.browse-teachers {
  .ig-input {
    margin: 0;
  }
}

.ig-input-course {
  .ig-input {
    margin: 0;
  }
}

.help-accordian {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.copy-link {
  display: inline-block;
  border: 1.5px solid #229a87;
  padding: 5px 10px;
  border-radius: 4px;
  color: #229a87;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#why-matrix {
  background: #f3fbf9;
  padding: 4em 0;
}

@media only screen and (max-width: 575.98px) and (min-width: 100px) {
  html {
    font-size: 14px;
  }

  .banner-block {
    border-radius: 0;
  }
}

@media only screen and (max-width: 991.98px) and (min-width: 575.98px) {
  html {
    font-size: 15px;
  }
  #dashboard-navbar .inner {
    padding: 10px;
  }
}

@media (max-width: 575.98px) {
  .d-mobile-block {
    display: block;
  }

  .d-mobile-none {
    display: none;
  }
}

@media (max-width: 787.98px) {
  .d-tablet-block {
    display: block;
  }

  .d-tablet-none {
    display: none;
  }

  .w-mobile-100 {
    min-width: 100%;
  }

  .footer-content {
    padding: 15px !important;

    h5 {
      margin-top: 20px;
    }
  }

  .index-block {
    max-height: 80vh;
    height: 80vh;
  }
}

@media (max-width: 991.98px) {
  .d-laptop-block {
    display: block;
  }

  .d-laptop-none {
    display: none;
  }

  .max-view {
    max-width: 100%;
    min-width: 100%;
    height: max-content;
  }
}

@media (max-width: 1199.98px) {
  .d-large-display-block {
    display: block;
  }

  .d-large-display-none {
    display: none;
  }
}

#blog-page {
  .emty {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 2em;
  }
}

.ig-phone {
  select {
    padding: 5px !important;
    height: 40px !important;
    font-size: 15px !important;
  }
}

.ig-input-frame {
  input {
    height: 40px !important;

    &::placeholder {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }
}

.auth-modal {
  .ig-input {
    margin-bottom: 10px;
  }

  .ig-input-label {
    font-weight: 500;
  }
}

.arrow-grid {
  display: grid;
  grid-template-columns: auto 100px;

  h4 {
    margin-bottom: 0;
    font-size: 1em;

    small {
      font-weight: 600;
    }
  }
}

.custom-card {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid rgba(185, 213, 208, 0.5);
  overflow: hidden;

  &__header {
    border-bottom: 1px solid rgba(185, 213, 208, 0.5);
    background: #f5f5f5;
    padding: 10px 25px;
    display: grid;
    display: grid;
    grid-template-columns: auto 100px;

    .title {
      font-size: 1em;
      margin-bottom: 0;
      color: $primary-color;

      span {
        padding-right: 3px;
        font-size: 14px;
      }
    }

    .title-grey {
      font-size: 0.3em;
      margin-bottom: 0;
      color: #333;

      span {
        padding-right: 3px;
        font-size: 14px;
      }
    }

    .back-navigation {
      color: $primary-color;
      text-align: right;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__body {
    background: #fff;
    padding: 20px 10px;
  }
}
.dashboard-responsive {
  @media (max-width: 991px) {
    margin-bottom: 50px;
  }
}
