@media (max-width: 576px) {
  .go-to-board .shape {
    display: none;
  }

  .right-content-view-scroll {
    padding: 0;
  }

  .dashboard .right-block {
    background: none;
    box-shadow: none;
    border-radius: 0;
  }

  .go-to-board {
    margin-top: 0;
    box-shadow: none;
    border: none;
    padding: 0 !important;
  }
}


@media(max-width: 991px) {
  #dashboard-copyright {
    margin-bottom: 57px;
  }
}