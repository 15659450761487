.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* default css */
.c-pointer {
  cursor: pointer;
}

/* menu */
.bg-white {
  background-color: #fff;
}
.card-skel {
  border-radius: 4px;
  box-shadow: 0px 0px 5px #ccc;
}
.min-50 {
  min-height: 50vh;
}
.min-80 {
  min-height: 80vh;
}
.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.star-rating button span {
  font-size: 1.1em;
}
.browse-teacher-tab .star-rating button span {
  font-size: 1em;
}
.star-rating .on {
  color: #f4bb44!important;
}
.star-rating .off {
  color: #ccc;
}
.d-max-fit-content {
  max-width: max-content;
}
@media only screen and (max-width: 900px) {
  .d-max-fit-content {
    max-width: 100%;
    padding: 0 5px;
  }
  .mb-pad-10 {
    padding: 0 10px;
  }
  .mb-pad-15 {
    padding: 0 15px;
  }
  .enquiry-card-mb .col-md-3 {
    max-width: 50%;
  }
}

/* coupon list css */
/* .coupon-block {
  background-color: #fff;
  padding: 5px 10px;
} */
.coupons-list {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}
.coupons-list li {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  display: block !important;
}
.coupons-list li h6 {
  font-weight: 600;
  margin-bottom: 0;
}
.coupons-list li span {
  font-size: 0.7em;
}
/* .applied-coupon {
  color: #dc3545;
  padding: 5px;
  background-color: #dc35451c;
  border: 1px solid #dc3545;
  border-radius: 4px;
} */

.tb-hide {
  display: block;
}
.mb-hide {
  display: block;
}
.mb-visible {
  display: none;
}
.tb-visible {
  display: none;
}
.enquiry-list-block-content {
  padding: 0px 10px 0 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mb-hide {
    display: none;
  }
  .mb-visible {
    display: block;
  }
  .enquiry-list-block-content {
    padding: 10px 0 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tb-hide {
    display: none;
  }
  .tb-visible {
    display: block;
  }
  .enquiry-list-block-content {
    padding: 10px 0 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .tb-hide {
    display: block;
  }
  .mb-hide {
    display: block;
  }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
.modal {
  max-height: 93vh;
}