.google-sign-in{
    width: 80%;
    margin-left: 10%;
}

.my-modal{
    border-radius: 0.5rem;
    /* width: 660px; */
    background-color: wheat;
    padding-bottom: 20px;
}

.my-modal_1{
    border-radius: 0.5rem;
    width: 990px;
    background-color: wheat;
    padding-bottom: 20px;
}

.modal-backdrop{
    width: 100%;
    height: 100%;
}