#home-page {
  overflow: hidden;
  background-color: #fff;
}

#learn-any-thing {
  padding: 2em 4em;

  @media (max-width : 1200px) {
    padding: 2em;
  }

  @media (max-width: 576px) {
    padding: 2em 0;
  }

  .section-heading {
    margin-bottom: 2.5em;
  }

  .learn-anything-slider {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    list-style: none;
    gap: 15px;
    width: 100%;

    @media (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    li {
      .each {
        background: #FFFFFF;
        box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        min-height: 200px;
        position: relative;
        overflow: hidden;
        transition: all 0.2s ease-in-out;

        @media (max-width: 576px) {
          min-height: 135px;
        }

        &:hover {
          box-shadow: 5px 0px 40px rgba(185, 213, 208, 1);
          cursor: pointer;
        }

        .image {
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 5px;
          bottom: 0px;

          img {
            max-width: 120px;


          }

          @media (max-width: 576px) {
            height: 80px;

            img {
              max-width: 50px;
            }
          }
        }

        .content {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          text-align: left;

          h3 {
            margin: 0;
            color: #fff;
            font-weight: 700;
            font-size: 1.3em;
            line-height: 22px;
          }

          p {
            color: #fff;
            margin: 2px 0 0 0;
            padding: 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }

      &:nth-child(1) {
        .each {
          background: #f6bd60;
          background-image: linear-gradient(to top, #f6bd60 0%, #e0ab55 100%);
        }
      }

      &:nth-child(2) {
        .each {
          background: #cb997e;
        }
      }

      &:nth-child(3) {
        .each {
          background: #98c1d9;
        }
      }

      &:nth-child(4) {
        .each {
          background: #f4978e;
        }
      }

      &:nth-child(5) {
        .each {
          background: #a2d6f9;
        }
      }

      &:nth-child(6) {
        .each {
          background: #92e6a7;
        }
      }

      &:nth-child(7) {
        .each {
          background: #d2b7e5;
        }
      }

      &:nth-child(8) {
        .each {
          background: #889696;
        }
      }

      &:nth-child(9) {
        .each {
          background: #b8b08d;
        }
      }

      &:nth-child(10) {
        .each {
          background: #b56576;
        }
      }
    }
  }
}

#how-it-works {
  padding: 4em;

  @media (max-width: 576px) {
    padding: 2em 1em;
  }

  .section-heading {
    margin-bottom: 3em;
  }

  .each-work {
    text-align: center;
    box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
    padding: 30px 25px;
    border-radius: 10px;

    @media (min-width: 768px) {
      min-height: 450px;
    }

    @media (max-width: 576px) {
      padding: 20px;
    }

    .image {
      height: 170px;
      display: flex;
      justify-content: center;
      margin-bottom: 2em;

      @media (max-width: 576px) {
        height: 100px;
      }
    }

    .content {
      h4 {
        font-size: 1.05em;
        color: #229a87;
        margin-bottom: 5px;
        font-weight: 600;
      }

      p {
        font-size: 0.9em;
        line-height: 22px;
        margin-bottom: 0;
      }

      .other {
        margin-top: 1em;
      }
    }
  }
}

.download-strip {
  text-align: center;
  // background: #def9f1;
  padding: 2em 0;
  text-align: center;
  background: url("../../images/backgrounds/download-strip.svg");
  background-size: cover;
  background-position: left;

  .heading {
    margin-bottom: 2em;

    img {
      margin-bottom: 10px;
    }
  }

  .app-links {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      &:not(:last-child) {
        margin-right: 15px;
      }


      img {
        max-width: 150px;
      }

      &:nth-child(1) {
        img {
          max-width: 175px;
        }
      }
    }
  }


}

.app-links {
  display: flex;
  margin-top: 15px;

  li {
    &:not(:last-child) {
      margin-right: 15px;
    }

    img {
      max-width: 100px;
    }

    &:nth-child(2) {
      img {
        max-width: 85px;
      }
    }
  }
}

.who-get-benefited {
  padding: 2em 0;
  background: #F3FCF9;

  .container {
    max-width: 70%;
  }

  .primary-button {
    padding: 5px 20px;
    border: none;
    background: #f9ed35;
    margin: 0 5px;
    font-size: 0.85em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    color: #333;
    margin: 20px 0;
    display: inline-block;
  }
}