#membership-page {
  @media (max-width: 991px) {
    background: #fff;
  }

  .shadow-theme {
    padding: 20px;

    @media (max-width: 991px) {
      box-shadow: none;
    }

    @media (max-width: 576px) {
      padding: 5px 10px;
    }
  }

  .main-title {
    font-size: 18px;
    letter-spacing: -0.2px;

    @media (max-width: 576px) {
      font-size: 16px;
    }

    .name {
      font-weight: 600;
      color: #229a87;
      padding-right: 5px;

      @media (max-width:400px) {
        display: block;
        width: 100%;
      }
    }

    .value {
      color: #dc3545;
      font-size: 20px;

      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    .date {
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .benefits {
    margin-top: 10px;

    h5 {
      font-size: 1em;
      color: #229a87;
      font-weight: 600;
      margin-bottom: 10px;
    }

    ul {
      margin-bottom: 15px;

      li {
        display: grid;
        margin: 5px 0;
        grid-template-columns: 20px auto;

        span {
          line-height: 20px;
          font-size: 14px;
        }

        p {
          font-size: 14px;
          margin: 0;
          line-height: 20px;
        }
      }
    }
  }

  .history-plans {
    width: 100%;

    .tab-data {
      margin-top: 15px;
    }

    .subscription-history {
      .title-block {
        margin-bottom: 15px;

        @media (max-width: 576px) {
          margin-bottom: 0;
        }
      }

      .title {
        color: #229a87;
        font-size: 1.1em;
        margin-bottom: 0;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0;
      }

      span {
        font-size: 13px;
      }
    }
  }
}