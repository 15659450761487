.plans-list {
  list-style: none;
}
.plans-list > li {
  float: left;
  width: 30%;
  /* border: 1px solid #ddd; */
  margin: 1.65%;
  padding: 0px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  box-shadow: 5px 0px 30px rgba(185, 213, 208, 0.5);
  border-radius: 20px;
}
.selected-star {
  position: absolute;
  width: 99px;
  height: 99px;
  left: 299px;
  top: 808px;
  background: #ff3d00;
  border-radius: 3.73026px;
  transform: matrix(0.98, -0.19, 0.19, 0.98, 0, 0);
  z-index: 100;
}
.plans-list > li > .current-text {
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;
}
.plans-list > li > .current-text > .current-label {
  padding: 5px 10px;
  color: #fff;
  font-size: 0.7rem;
  background-color: #229a87;
  border-radius: 4px;
}
.plans-list > li.selected {
  border-color: #229a87;
}
.plan-head {
  padding: 40px 10px 20px;
  /* border-bottom: 1px solid #ddd; */
  text-align: center;
}
.plan-price {
  padding: 20px 10px 10px;
  /* border-bottom: 1px solid #ddd; */
  text-align: center;
}
.plan-footer {
  padding: 20px 10px 10px;
  text-align: center;
  height: 100px;
}
.plan-desc {
  padding: 10px 20px;
  height: 150px;
}
.plan-desc,
.desc-list {
  list-style: disc;
  margin: 0px 10px;
}
.plan-desc,
.desc-list li {
  font-size: 0.8rem;
}

.plan-body {
  min-height: 40vh;
}
.form-check {
  display: flex;
  justify-content: center;
}
.payment-block {
  border: 1px solid #ddd;
  border-radius: 4px;
  /* min-height: 40vh; */
  background-color: #fff;
}
