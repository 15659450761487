#about-page {
  // .inner {
  //   padding: 3em 0;
  // }

  .main-heading {
    color: #229a87;
    font-size: 1.3em;
    margin-bottom: 1em;
  }

  .sub-heading {
    color: #229a87;
    font-size: 1.1em;
    margin-bottom: 1em;
  }

  p {
    font-size: 0.95em;
    line-height: 1.75;
    margin: 0;
    color: #333;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
}